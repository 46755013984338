import '../styles/index.scss';
import 'swiper/swiper-bundle.css';
import Swiper from 'swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import initGoogleMap from './googleMap';

SwiperCore.use([Pagination, Autoplay]);

if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let passiveSupported = false;

try {
  const options = {
    get passive() {
      // This function will be called when the browser
      //   attempts to access the passive property.
      passiveSupported = true;
      return false;
    },
  };

  window.addEventListener('test', null, options);
  window.removeEventListener('test', null, options);
} catch (err) {
  passiveSupported = false;
}

const wheelOpt = passiveSupported ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

window.addEventListener('load', () => {
  if (window.location.hash) {
    const targetScroll = document.querySelector(`.js-scroll-target-${window.location.hash.split('#')[1]}`);
    targetScroll.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  // Change style of navbar on scroll
  window.onscroll = function (e) {
    myFunction();
  };

  function myFunction() {
    var navbar = document.getElementById('myNavbar');
    const header = document.getElementById('header');

    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      navbar.className = 'zz-bar' + ' zz-card' + ' zz-animate-top' + ' zz-white';

      header.className = 'zz-top';
    } else {
      navbar.className = navbar.className.replace('zz-card zz-animate-top', '');

      header.className = 'zz-top my-navbar-border';
    }
  }

  // Used to toggle the menu on small screens when clicking on the menu button
  window.toggleFunction = function toggleFunction() {
    const navbarMenu = document.getElementById('mobileNavbarMenu');
    const maskElement = document.getElementById('mask-effect');
    const toggleButtons = document.querySelectorAll('.navbar__toggle-btn-line');

    if (!navbarMenu.classList.contains('is-active')) {
      navbarMenu.classList.add('is-active');
      maskElement.style.display = 'block';
      disableScroll();

      [...toggleButtons].forEach((item) => item.classList.add('is-active'));
    } else {
      navbarMenu.classList.remove('is-active');
      maskElement.style.display = 'none';
      enableScroll();

      [...toggleButtons].forEach((item) => item.classList.remove('is-active'));
    }
  };

  window.addEventListener('resize', () => {
    if (window.innerWidth > 600) {
      const maskElement = document.getElementById('mask-effect');
      const toggleButtons = document.querySelectorAll('.navbar__toggle-btn-line');
      var navbarMenu = document.getElementById('mobileNavbarMenu');
      if (!navbarMenu.classList.contains('is-active')) {
        navbarMenu.classList.remove('is-active');
        maskElement.style.display = 'none';

        [...toggleButtons].forEach((item) => item.classList.remove('is-active'));
        enableScroll();
      }
    }
  });

  const swiperEl = document.querySelector('.swiper-container');
  if (swiperEl) {
    swiperEl.style.height = `${swiperEl.parentElement.clientHeight}px`;
    const swiper = new Swiper('.swiper-container', {
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      speed: 1200,
      loop: true,
      initialSlide: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
    swiper.autoplay.start();
  }

  const links = document.querySelectorAll('.js-scroll-to');

  [...links].forEach((linkItem) => {
    linkItem.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.dataset.scrollTarget;
      const target = document.querySelector(`.js-scroll-target-${targetId}`);

      target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      window.location.hash = `#${targetId}`;
    });
  });
});

window.addEventListener('resize', () => {
  const swiperEl = document.querySelector('.swiper-container');

  if (swiperEl) {
    swiperEl.style.height = `${swiperEl.parentElement.clientHeight}px`;
  }
});

document.addEventListener('readystatechange', () => {
  if (document.readyState === 'complete') {
    initGoogleMap();
  }
});
